import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/index'

Vue.use(Vuex)

const userInfoStr = localStorage.getItem('userInfo')
//
export default new Vuex.Store({
  state: {
    // 用户信息
    userInfo: userInfoStr && userInfoStr !== 'undefined' ? JSON.parse(localStorage.getItem('userInfo')) : {}
  },
  mutations: {
    // 设置当前用户信息
    setUserInfo (state, data) {
      state.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getUserInfo().then(res => {
          if (res.data.code === 0) {
            commit('setUserInfo', res.data.data)
            resolve()
          } else {
            console.log('获取个人信息错误')
          }
        }).catch((err) => {
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})
