// 全局变量
const globalVar = {
  /**
   * tokenName
   */
  TOKEN: 'token',
  CARDNO: '6217 0000 0000 0000 767'
}
const UPLOADURL = {
  URL: process.env.VUE_APP_API_BASE_URL + 'api/v1/media/put'
}
/**
 * 任务难度水平
 * 0 普通, 1 一般, 2 复杂, 3 困难
 */
const TASK_LEVEL = {
  /**
   * 普通
   */
  COMMON: 0,
  /**
   * 一般
   */
  GENERAL: 1,
  /**
   * 复杂
   */
  COMPLEX: 2,
  /**
   * 困难
   */
  DIFFICULTY: 3
}
/**
 * 提现充值状态
 */
const WITHDRAW_STATUS = {
  /**
   * 待处理
   */
  PENDING: 0,
  /**
   * 已处理
   */
  PROCESSED: 1,
  /**
   * 已取消
   */
  CANCELED: 2
}
const COMPANY_STATUS = {
  VERIFIED: 2,
  APPLYING: 1,
  UNVERIFIED: 0
}
// 任务状态
const TASK_STATUS = {
  /**
   * 待审核
   */
  AUDIT: 0,
  /**
   * 已发布
   */
  PUBLISHED: 1,
  /**
   * 已驳回
   */
  REJECT: 2,
  /**
   * 进行中
   */
  ONGOING: 3,
  /**
   * 已完成
   */
  SUCCESSFUL: 4,
  /**
   * 已取消
   */
  CANCELED: 5,
  /**
   * 等待企业确认完成
   */
  WAITING_FOR_COMPANY: 6,
  /**
   * 等待奖励发放
   */
  WAITING_FOR_REWARDS: 7
}
export default {
  ...globalVar,
  TASK_LEVEL,
  COMPANY_STATUS,
  WITHDRAW_STATUS,
  UPLOADURL,
  TASK_STATUS
}
