import { post, get, put, remove } from '../plugins/axios'

// 登录模块
const login = {
  /**
   * 管理员登录
   * @param {*} data
   */
  adminLogin: (data) => post('admin/api/v1/login', data),
  /**
   * web发送短信验证码
   * @param {*} data
   */
  sendSmsCode: (data) => post('api/v1/auth/send_code', data),
  /**
   * web注册
   * @param {*} data
   */
  register: (data) => post('api/v1/auth/register', data),
  /**
   * web忘记密码，找回密码
   * @param {*} data
   */
  frontForgetPassword: (data) => post('api/v1/auth/forgot', data),
  /**
   *
   * web前台登录
   * @param {*} data
   */
  frontLogin: (data) => post('api/v1/auth/login', data)
}

// 任务模块
const task = {
  /**
   * @param {*} data
   */
  adminTaskList: (data) => get('admin/api/v1/task/list', data),
  /**
   * admin通过任务
   * @param {*} data
   */
  adminPassTask: (id) => put(`admin/api/v1/task/${id}/pass`),
  /**
   * admin驳回任务
   * @param {*} id
   */
  adminRejectTask: (id) => put(`admin/api/v1/task/${id}/reject`),
  /**
   * admin关闭任务
   * @param {*} id
   */
  adminCloseTask: (id) => put(`admin/api/v1/task/${id}/close`),
  /**
   * admin修改任务奖励
   * @param {*} id
   * @param {*} data
   */
  adminUpdateReward: (id, data) => put(`admin/api/v1/task/reward/${id}/change`, data),
  /**
   * user获取任务列表
   * @param {*} data
   */
  userTaskList: (data) => get('api/v1/tasks', data),
  /**
   * 任务大厅获取任务列表
   * @param {*} data
   */
  taskHallList: (data) => get('/api/v1/tasks/center', data),
  /**
   * 获取任务详情
   * @param {*} id
   */
  taskDetail: (id) => get(`api/v1/tasks/${id}`),
  /**
   * 详情任务中的行为消息展示
   * @param {*} id
   */
  taskLogList: (id) => get(`api/v1/tasks/${id}/log/list`),
  /**
   *
   * web企业发布任务
   * @param {*} data
   */
  publishTask: (data) => post('api/v1/company/tasks', data),
  /**
   *
   * web获取当前和历史任务
   * @param {*} data
   */
  HistoryTask: (data) => get('api/v1/company/tasks', data),
  /**
   *
   * web企业验收任务
   * @param {*} data
   * @param {*} id
   */
  AcceptanceTask: (id, data) => put(`api/v1/tasks/${id}/check`, data),

  /**
   *id
   * web企业取消任务
   * @param {*} id
   */
  cancelTask: (id) => remove(`api/v1/tasks/${id}/cancel`),
  /**
   *id
   * web企业打回重做任务
   * @param {*} id
   */
  repulseTask: (id) => post(`api/v1/taskings/${id}/rollback`)
}

// 用户管理模块
const userManagement = {
  /**
   * admin获取用户列表
   * @param {*} data
   */
  adminUserList: (data) => get('admin/api/v1/user/list', data),
  /**
   * admin启用用户账号
   * @param {*} id
   */
  adminUserEnable: (id) => put(`admin/api/v1/user/${id}/enable`),
  /**
   * admin禁用用户账号
   * @param {*} id
   */
  adminUserDisable: (id) => put(`admin/api/v1/user/${id}/disable`),
  /**
   * admin获取用户详情
   * @param {*} id
   */
  adminUserDetail: (id) => get(`admin/api/v1/user/${id}`),
  /**
   * admin企业认证
   * @param {} id
   * @param {*} data
   */
  adminCertification: (id, data) => put(`admin/api/v1/user/${id}/certification`, data),
  /**
   * admin获取后台账号列表
   * @param {*} data
   */
  adminUserAccountList: (data) => get('admin/api/v1/admin/user/list', data),
  /**
   * admin启用后台账号
   * @param {*} id
   */
  adminEnable: (id) => put(`admin/api/v1/admin/user/${id}/enable`),
  /**
  * admin禁用后台账号
  * @param {*} id
  */
  adminDisable: (id) => put(`admin/api/v1/admin/user/${id}/disable`),
  /**
   * 添加后台账号
   * @param {*} data
   */
  adminAdd: (data) => post('admin/api/v1/admin/user', data),
  /**
   * 获取企业认证列表
   * @param {*} data
   */
  authList: (data) => get('admin/api/v1/com/auth/list', data)
}

// 资金相关模块
const funds = {
  /**
   * admin获取全部提现申请记录列表
   * @param {} data
   */
  adminWithDrawalList: (data) => get('admin/api/v1/withdrawal/list', data),
  /**
   * admin确认提现申请
   * @param {*} id
   */
  adminWithDrawalConfirm: (id) => put(`admin/api/v1/withdrawal/${id}/confirm`),
  /**
   * admin取消提现申请
   * @param {*} id
   */
  adminWithDrawalCancel: (id) => put(`admin/api/v1/withdrawal/${id}/cancel`),
  /**
   * admin获取全部充值申请记录列表
   * @param {*} data
   */
  adminRechargeList: (data) => get('admin/api/v1/recharge/list', data),
  /**
   * admin确认充值申请
   * @param {*} id
   */
  adminRechargeConfirm: (id) => put(`admin/api/v1/recharge/${id}/confirm`),
  /**
   * admin取消充值申请
   * @param {*} id
   */
  adminRechargeCancel: (id) => put(`admin/api/v1/recharge/${id}/cancel`)
}

// 申诉管理模块
const appeal = {
  /**
   * admin获取全部申诉记录列表
   * @param {*} data
   */
  adminAppealList: (data) => get('api/v1/task/appeal/list', data),
  /**
   * admin申诉处理完成
   * @param {*} id
   */
  adminAppealComplete: (id) => put(`api/v1/task/appeal/${id}/complete`),
  /**
   * admin申诉详情
   * @param {*} id
   */
  adminAppealDetail: (id) => get(`api/v1/task/appeal/${id}`),
  /**
   * 用户提交任务申诉
   * @param {*} id
   * @param {*} data
   */
  userAppeal: (id, data) => post(`api/v1/tasks/${id}/appeal`, data)
}

// 文件上传模块
const file = {
  /**
   * 文件展示
   * @param {*} id
   */
  showFile: (id) => get(`api/v1/media/${id}`)
}

// 个人相关
const userInfo = {
  /**
   * 获取当前用户信息
   * @returns
   */
  getUserInfo: () => get('api/v1/user/me'),
  /**
   * 企业提交认证申请
   * @returns
   */
  companyCertification: (data) => put('api/v1/user/certify', data),
  /**
   *
   * 更新web企业信息
   * @param {*} data
   */
  enterpriseCertification: (data) => put('api/v1/user/me', data),
  /**
   *
   * web企业充值提交
   * @param {*} data
   */
  recharge: (data) => put('api/v1/recharge', data),
  /**
   *
   * web企业消费记录
   * @param {*} data
   */
  recordsConsumption: (data) => get('/api/v1/daybook/expenses', data),
  /**
   * 更新当前用户信息，修改账号角色
   * @returns
   */
  updateUserInfo: (data) => put('api/v1/user/me', data),
  /**
   * 中间也创建用户的基本信息
   * @returns
   */
  middleLogin: (data) => post('api/v1/user/information', data)
}

// 任务关联模块
const taskRelation = {
  /**
   * 用户接受任务
   * @param {*} id
   */
  acceptTask: (id) => post(`/api/v1/tasks/${id}/taskings/`),
  /**
   * 展现进行中的任务
   * @returns
   */
  currentTask: () => get('api/v1/taskings/current'),
  /**
   * 展示历史任务列表
   * @param {*}data
   */
  HistoryTaskList: (data) => get('api/v1/taskings/myself', data),
  /**
   * 用户确认提交任务
   * @param {*}id
   */
  confirmCommit: (id) => put(`api/v1/taskings/${id}/ok`),
  /**
   * 用户放弃任务
   * @param {*}id
   */
  abandonTask: (id) => post(`api/v1/tasks/${id}/abandon`),
  /**
   * 上传材料-用户
   * @param {*} id
   * @param {*} data
   */
  userTaskingsCommit: (id, data) => put(`api/v1/taskings/${id}/commit`, data)
}

// 消息模块
const message = {
  /**
   * 获取消息通知列表
   * @returns
   */
  getNotify: (data) => get('api/v1/sys/notify/list', data),
  /**
   * 消息全部已读
   * @returns
   */
  addRead: () => put('api/v1/sys/notify/read/all'),
  /**
   * 单个消息已读
   * @param {*} id
   * @returns
   */
  oneRead: (id) => put(`api/v1/sys/notify/${id}/read`)
}

// 获取当前用户信息模块
const GetUserInformation = {
  /**
 * web获取当前用户信息
 * @param {*} data
 */
  getUserInfo: (data) => get('api/v1/user/me', data)
}

// web企业充值消费模块
const companyRecharge = {
  /**
   * web企业端获取全部充值申请记录列表
   * @param {*} data
   */
  webRechargeList: (data) => get('api/v1/recharge/list', data),

  /**
   * web企业端获取全部消费申请记录列表
   * @param {*}
   */
  consumption: (data) => get('api/v1/daybook/expenses', data)
}
// 资产模块
const Reward = {
  /**
   * web用户端提现申请
   * @param {*} data
   */
  withdrawalApplication: (data) => post('api/v1/withdrawal', data),
  /**
   * web用户端提现记录列表
   * @param {*}
   */
  withdrawalListTable: () => get('api/v1/withdrawal/list')
}
// 收支记录模块
const revenueAndExpenditure = {
  /**
   * web用户端展示收益记录
   * @param {*}
   */
  profit: () => get('api/v1/daybook/incomes')
}
export default {
  ...login,
  ...task,
  ...funds,
  ...appeal,
  ...userManagement,
  ...file,
  ...userInfo,
  ...companyRecharge,
  ...GetUserInformation,
  ...taskRelation,
  ...message,
  ...Reward,
  ...revenueAndExpenditure
}
