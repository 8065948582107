import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/front/login/index.vue')
  },
  {
    path: '/middle-login',
    component: () => import('@/views/front/login/middle-page/index.vue')
  },
  {
    path: '/admin-login',
    component: () => import('@/views/back/login/index.vue')
  },
  // 用户端
  {
    path: '/user',
    component: () => import('@/views/front/user/index.vue'),
    redirect: '/user/task-hall',
    children: [
      // 用户端任务大厅
      {
        path: 'task-hall',
        component: () => import('@/views/front/user/task-hall/index.vue'),
        meta: {
          nav: '/user/task-hall'
        }
      },
      // 用户端任务大厅中的任务详情
      {
        path: 'task-detail/:taskId',
        component: () => import('@/views/front/user/task-hall/detail-pages/task-detail.vue'),
        meta: {
          nav: '/user/task-hall'
        }
      },
      // 用户端任务管理
      {
        path: 'task-manager',
        component: () => import('@/views/front/user/task-manager/index.vue'),
        meta: {
          nav: '/user/task-manager'
        }
      },
      // 用户端任务管理中的历史任务详情
      {
        path: 'historic-task-detail/:taskId',
        component: () => import('@/views/front/user/task-manager/detail-pages/historic-task-detail.vue'),
        meta: {
          nav: '/user/task-manager'
        }
      },
      // 用户端任务管理中的当前任务详情
      {
        path: 'current-task-detail/:taskId',
        component: () => import('@/views/front/user/task-manager/detail-pages/current-task-detail.vue'),
        meta: {
          nav: '/user/task-manager'
        }
      },
      // 用户端个人中心
      {
        path: 'personal',
        component: () => import('@/views/front/user/personal/index.vue'),
        meta: {
          nav: '/user/personal'
        }
      }
    ]
  },
  // 企业端
  {
    path: '/company',
    component: () => import('@/views/front/company/index.vue'),
    redirect: '/company/task-hall',
    children: [
      // 企业端任务大厅
      {
        path: 'task-hall',
        component: () => import('@/views/front/company/task-hall/index.vue'),
        meta: {
          nav: '/company/task-hall'
        }
      },
      // 企业端任务大厅中的任务详情
      {
        path: 'task-detail/:taskId',
        component: () => import('@/views/front/company/task-hall/detail-pages/task-detail.vue'),
        meta: {
          nav: '/company/task-hall'
        }
      },
      // 企业端任务管理
      {
        path: 'manage',
        component: () => import('@/views/front/company/task-manager/index.vue'),
        meta: {
          nav: '/company/manage'
        }
      },
      // 企业端任务管理中的任务详细
      {
        path: 'task-mgt-detail/:taskId',
        component: () => import('@/views/front/company/task-manager/detail-pages/handle-task-detail.vue'),
        meta: {
          nav: '/company/manage'
        }
      },
      // 企业端任务管理中的历史任务详情
      {
        path: 'history-task-detail/:taskId',
        component: () => import('@/views/front/company/task-manager/detail-pages/history-task-detail.vue'),
        meta: {
          nav: '/company/manage'
        }
      },
      {
        // 发布任务详情
        path: 'task-release/:taskId?',
        component: () => import('@/views/front/company/task-manager/detail-pages/release-task-detail.vue'),
        meta: {
          nav: '/company/manage'
        }
      },
      // 企业端个人中心
      {
        path: 'center',
        component: () => import('@/views/front/company/company-center/index.vue'),
        meta: {
          nav: '/company/center'
        }
      },
      {
        // 企业认证页面
        path: 'company-detail',
        component: () => import('@/views/front/company/company-center/detail-pages/authentication-detail.vue'),
        meta: {
          nav: '/company/center'
        }
      }
    ]
  },
  // 后台管理端
  {
    path: '/admin',
    component: () => import('@/views/back/admin/index.vue'),
    redirect: '/admin/manager',
    children: [
      // 后台管理端任务管理
      {
        path: 'manager',
        component: () => import('@/views/back/admin/manager/index.vue'),
        meta: {
          nav: '/admin/manager'
        }
      },
      // 任务审核详情
      {
        path: 'audit-detail/:taskId',
        component: () => import('@/views/back/admin/manager/detail-pages/audit.vue'),
        meta: {
          nav: '/admin/manager'
        }
      },
      // 关闭任务详情
      {
        path: 'off-task/:taskId',
        component: () => import('@/views/back/admin/manager/detail-pages/off-task.vue'),
        meta: {
          nav: '/admin/manager'
        }
      },
      // 修改任务奖励详情
      {
        path: 'update-awards/:taskId',
        component: () => import('@/views/back/admin/manager/detail-pages/update-awards.vue'),
        meta: {
          nav: '/admin/manager'
        }
      },
      // 后台管理端账号管理
      {
        path: 'account',
        component: () => import('@/views/back/admin/account/index.vue'),
        meta: {
          nav: '/admin/account'
        }
      },
      // 后台管理端企业认证审核
      {
        path: 'certification',
        component: () => import('@/views/back/admin/company-certification/index.vue'),
        meta: {
          nav: '/admin/certification'
        }
      },
      // 后台管理端企业认证审核详情
      {
        path: 'certification-detail/:companyId',
        component: () => import('@/views/back/admin/company-certification/company-info-detail.vue'),
        meta: {
          nav: '/admin/certification'
        }
      },
      // 后台管理端申诉处理
      {
        path: 'complains',
        component: () => import('@/views/back/admin/complaints/index.vue'),
        meta: {
          nav: '/admin/complains'
        }
      },
      // 申诉详情
      {
        path: 'complaints-update/:taskId',
        component: () => import('@/views/back/admin/complaints/detail-pages/complaints-detail.vue'),
        meta: {
          nav: '/admin/complains'
        }
      },
      // 后台管理端提现与充值
      {
        path: 'withdraw',
        component: () => import('@/views/back/admin/withdraw/index.vue'),
        meta: {
          nav: '/admin/withdraw'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
